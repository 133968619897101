import Analytics from 'components/Analytics'
import SEO from 'components/SEO'
import { graphql } from 'gatsby'
import useLayout from 'hooks/useLayout'
import { renderContentBlocks } from 'lib/renderContentBlocks'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { productsActions } from 'store/products/slice'

const PageTemplate = ({ data: { page, allSanityProduct }, pageContext: { pageUrl } }) => {
  const layout = useLayout()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(productsActions.setProducts(allSanityProduct.nodes))
  }, [])

  useEffect(() => {
    if (page.template) {
      layout.setShowHeader(!page.template.hideHeader)
      layout.setShowFooter(!page.template.hideFooter)
      layout.setShowFDADisclaimer(page.template.showFDADisclaimer)
      layout.setShowSecondDisclaimer(page.template.showSecondDisclaimer)
    }
    return () => {
      layout.setShowHeader(true)
      layout.setShowFooter(true)
      layout.setShowFDADisclaimer(false)
      layout.setShowSecondDisclaimer(false)
    }
  }, [page.template, layout])

  return (
    <>
      <SEO defaultMeta={{ metaTitle: page.title }} pageMeta={page.meta} pagePath={pageUrl} />
      <Analytics {...page.analytics} />
      {renderContentBlocks(page.contentBlocks)}
    </>
  )
}

export default PageTemplate

PageTemplate.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      meta: PropTypes.object,
      analytics: PropTypes.object,
      template: PropTypes.shape({
        hideHeader: PropTypes.boolean,
        hideFooter: PropTypes.boolean,
        showFDADisclaimer: PropTypes.boolean,
        showSecondDisclaimer: PropTypes.boolean,
      }),
      title: PropTypes.string,
      contentBlocks: PropTypes.arrayOf(
        PropTypes.shape({
          _key: PropTypes.string.isRequired,
          _type: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    pageUrl: PropTypes.string.isRequired,
  }).isRequired,
}

export const query = graphql`
  query PageQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      meta {
        ...MetaCard
      }
      analytics {
        ...AnalyticsCard
      }
      template {
        hideHeader
        hideFooter
        showFDADisclaimer
        showSecondDisclaimer
      }
      title
      contentBlocks {
        ...Blocks
      }
    }
    allSanityProduct {
      nodes {
        ...Product
      }
    }
  }
`
